<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> NUEVO CLIENTE </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'clientesPanel' }"
                    >Clientes</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'clientesCrearPanel' }"
                    >Crear</router-link
                  >
                </p>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="guardar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="nombre"
                    item-value="idtipo_documento_identidad"
                    :items="arrayTipoDocumentoIdentidad"
                    label="Tipo de documento"
                    outlined
                    v-model="cliente.idtipo_documento_identidad"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="N° de documento"
                    v-model="cliente.numero_documento_identidad"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Nombres"
                    v-model="cliente.nombres"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Apellidos"
                    v-model="cliente.apellidos"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Correo"
                    v-model="cliente.correo"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Telefono"
                    v-model="cliente.telefono"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-menu :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatFechaDesde"
                        dense
                        clearable
                        label="Fecha de nacimiento"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="cliente.fecha_nacimiento = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="cliente.fecha_nacimiento"
                      locale="es-es"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="cmbSexo"
                    label="Sexo"
                    outlined
                    v-model="cliente.sexo"
                  ></v-select>
                </v-col>

                <!--<v-col cols="12">
                                <v-select dense item-text="texto" item-value="valor" :items="cmbEstado" label="Estado(*)" outlined v-model="cliente.estado"></v-select>
                            </v-col>-->

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save</v-icon> GUARDAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'clientesPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cliente: {
        idtipo_documento_identidad: "",
        nombres: "",
        apellidos: "",
        correo: "",
        numero_documento_identidad: "",
        telefono: "",
        sexo: "",
        fecha_nacimiento: "",
        estado: 1,
      },
      arrayTipoDocumentoIdentidad: [],
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      cmbSexo: [
        {
          texto: "M",
          valor: "M",
        },
        {
          texto: "F",
          valor: "F",
        },
      ],
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
    };
  },

  computed: {
    formatFechaDesde() {
      return this.cliente.fecha_nacimiento
        ? this.$moment(this.cliente.fecha_nacimiento).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    getDatos() {
      this.axios({
        method: "GET",
        url: "api/panel/clientes/create",
      })
        .then((response) => {
          if (response.status === 200) {
            this.arrayTipoDocumentoIdentidad = response.data.data.tipoDocumentoIdentidad;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.mostrarContenido = true;
        });
    },

    guardar() {
      this.alertError = false;
      this.processForm = true;

      this.axios({
        method: "POST",
        url: "api/panel/clientes",
        data: this.serialize(this.cliente),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "clientesPanel",
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al guardar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },

  created() {
    if (!this.validarPermiso("clientes.crear")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);
    this.getDatos();
  },
};
</script>
